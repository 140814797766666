.editor-container {
  /* max-width: 800px; */
  /* margin: 0 auto; */

  padding: 3rem 2rem;
}

.editor-form {
  display: flex;
  flex-direction: column;
  /* max-width: 80%; */
}

.editor-form label {
  margin-bottom: 1rem;
}

.editor-form input,
.editor-form textarea {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

.editor-form button {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
}

.editor-form button:hover {
  background-color: #0069d9;
}

.editor-form button:active {
  background-color: #0056b3;
}