.image-gallery {
    column-count: 1;
    column-gap: 1rem;
    padding: 1rem;
}

@media (min-width: 768px) {
    .image-gallery {
        column-count: 2;
    }
}

@media (min-width: 992px) {
    .image-gallery {
        column-count: 3;
    }
}

.image-wrapper {
    display: inline-block;
    margin: 0 0 1rem;
    width: 100%;
    break-inside: avoid;
}

.image-wrapper img {
    width: 100%;
    height: auto;
    object-fit: cover;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
}

.image-wrapper:hover img {
    opacity: 0.8;
}

.focused-image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow: hidden;
  }
  
  .focused-image-wrapper {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    text-align: center;
    overflow: auto;
    padding: 0 5%;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.focused-image-wrapper img {
    max-width: 100%;
    max-height: 100%;
    margin-bottom: 1rem;
    object-fit: contain;
}

.image-description {
    color: white;
    margin-top: 1rem;
}

.loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border-left-color: #00b4d8;
    animation: spin 1s linear infinite;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
  }
  
  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  