.blog-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    padding: 3rem 2rem;
}

.search-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 2rem;
}

.search-input {
  flex-grow: 1;
  padding: 0.5rem;
  margin-right: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.sort-select {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.blog-post {
  padding: 1rem;
  border: 2px solid #ccc;
  border-radius: .5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  z-index: 100;
  background-color: #ffffff;
}

.blog-post:hover {
  background-color: #eaeaea;
  transition: 0.4s;
}

body.dark-mode .blog-post {
  border: 2px solid #ffffff00;
  background-color: #7f75ec;
}
body.dark-mode .blog-post:hover {
  background-color: #635bb8;
}

.pagination {
  display: flex;
  justify-content: center;
}

.page-button {
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  background-color: white;
  cursor: pointer;
  margin: 0 0.25rem;
  border-radius: 4px;
}

.page-button:hover {
  background-color: #f5f5f5;
}

.page-button:active {
  background-color: #eee;
}

.blog-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    /* padding: 3rem; */
    padding: 3rem 2rem;
}