body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  /* background-color: #ffe9d8; */
  background-color: whitesmoke;
  color:#2b2931
}

a {
  text-decoration: none;
  /* color: #ABDEE6 */
}

a:hover {
  text-decoration: underline;
}

.App {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.colorLayer {
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(270deg, #FF968A, #e4ac62, #ABDEE6, #abade6, #CBAACB, #FCB9AA);
  background-size: 1200% 1200%;
  -webkit-animation: colorShift 60s ease infinite;
          animation: colorShift 60s ease infinite;
}

@-webkit-keyframes colorShift {
  0% {
    background-position: 0% 50%;
  }

  17% {
    background-position: 100% 50%;
  }

  34% {
    background-position: 0% 50%;
  }

  51% {
    background-position: 100% 50%;
  }

  68% {
    background-position: 0% 50%;
  }

  85% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes colorShift {
  0% {
    background-position: 0% 50%;
  }

  17% {
    background-position: 100% 50%;
  }

  34% {
    background-position: 0% 50%;
  }

  51% {
    background-position: 100% 50%;
  }

  68% {
    background-position: 0% 50%;
  }

  85% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

body.dark-mode {
  background-color: #1f1f25;
  color: #f0f0f0;
}

body.dark-mode .header {
  color: #f0f0f0;
}

body.dark-mode .aboutMe {
  background-color: #2b2b2b;
  color: #f0f0f0;
}

body.dark-mode .toggle-dark-mode {
  background-color: #232328;
  color: #f0f0f0;
}

body.dark-mode .navigation .colorLayer {
  background: linear-gradient(270deg, #2b2b2b, #1a1a1a);
}

/* Dark mode button styles */
.toggle-dark-mode {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 8px 16px;
  background-color: #fff;
  border: none;
  font-size: 16px;
  color: #1b1b1b;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1000;
}

.toggle-dark-mode:focus {
  outline: none;
}

.navWrap {
  display: flex;
}

.navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

.navigation ul {
  display: flex;
  list-style-type: none;
}

.navigation .nav-left a {
  margin-right: auto;
  color: #ffffff;
  font-weight: 600;
}

.navigation .nav-right {
  margin-left: auto;
  margin-right: 1rem;
  justify-content: flex-end;
}


.navigation li {
  /* margin-left: 1rem; */
  margin-right: 1rem;
}

.navigation a {
  color: #fff;
  text-decoration: none;
}

.navigation a:hover {
  text-decoration: underline;
}

.home-header {
  text-align: center;
  color: #fff;
  padding-top: 4rem;
  /* padding-bottom: 2rem; */
  margin: 1rem;
}

.text-box {
  display: flex;
  flex-direction: column;
  background-color: #4e4e4e;
  padding: 2rem 3rem;
  margin: 2rem;
  color: #fff;
  text-align: left;
  border-radius: 10px;
  opacity: 0.8;
  max-width: 60%;
  vertical-align: middle;
}

.text-box h2 {
  margin-top: 0;
}

.blogWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-top: 3rem;
}

.projectsWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-top: 3rem;
}

.artWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-top: 3rem;
}

.home-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* min-height: 100vh;
  padding-top: 3rem; */
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-left-color: #e1903f;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1001;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}

.link{
  color:#ABDEE6;
}
.project-box-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 70%;
}

.project-box {
    display: flex;
    flex-direction: column;
    background-color: #7f75ec;
    padding: 2rem 3rem;
    border: 2px solid #abdee600;
    /* margin: 3rem; */
    color: #fff;
    text-align: left;
    border-radius: 10px;
    opacity: 0.8;
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
    /* max-width: 60%; */
  }

.project-box:hover {
    /* border: 2px solid #ABDEE6; */
    background-color: #635bb8;
    transition: 0.4s;
}

body.dark-mode .project-box {
    /* background-color: #7f75ec; */
    color: #fff;
    opacity: 0.8;
}

a:hover {
    text-decoration: none;
}

.ascii-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    padding-top: 3rem;
}


.blog-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    padding: 3rem 2rem;
}

.search-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 2rem;
}

.search-input {
  flex-grow: 1;
  padding: 0.5rem;
  margin-right: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.sort-select {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.blog-post {
  padding: 1rem;
  border: 2px solid #ccc;
  border-radius: .5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  z-index: 100;
  background-color: #ffffff;
}

.blog-post:hover {
  background-color: #eaeaea;
  transition: 0.4s;
}

body.dark-mode .blog-post {
  border: 2px solid #ffffff00;
  background-color: #7f75ec;
}
body.dark-mode .blog-post:hover {
  background-color: #635bb8;
}

.pagination {
  display: flex;
  justify-content: center;
}

.page-button {
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  background-color: white;
  cursor: pointer;
  margin: 0 0.25rem;
  border-radius: 4px;
}

.page-button:hover {
  background-color: #f5f5f5;
}

.page-button:active {
  background-color: #eee;
}

.blog-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    /* padding: 3rem; */
    padding: 3rem 2rem;
}
.editor-container {
  /* max-width: 800px; */
  /* margin: 0 auto; */

  padding: 3rem 2rem;
}

.editor-form {
  display: flex;
  flex-direction: column;
  /* max-width: 80%; */
}

.editor-form label {
  margin-bottom: 1rem;
}

.editor-form input,
.editor-form textarea {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

.editor-form button {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
}

.editor-form button:hover {
  background-color: #0069d9;
}

.editor-form button:active {
  background-color: #0056b3;
}
.image-gallery {
    -webkit-column-count: 1;
            column-count: 1;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    padding: 1rem;
}

@media (min-width: 768px) {
    .image-gallery {
        -webkit-column-count: 2;
                column-count: 2;
    }
}

@media (min-width: 992px) {
    .image-gallery {
        -webkit-column-count: 3;
                column-count: 3;
    }
}

.image-wrapper {
    display: inline-block;
    margin: 0 0 1rem;
    width: 100%;
    page-break-inside: avoid;
    -webkit-column-break-inside: avoid;
            break-inside: avoid;
}

.image-wrapper img {
    width: 100%;
    height: auto;
    object-fit: cover;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
}

.image-wrapper:hover img {
    opacity: 0.8;
}

.focused-image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow: hidden;
  }
  
  .focused-image-wrapper {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    text-align: center;
    overflow: auto;
    padding: 0 5%;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.focused-image-wrapper img {
    max-width: 100%;
    max-height: 100%;
    margin-bottom: 1rem;
    object-fit: contain;
}

.image-description {
    color: white;
    margin-top: 1rem;
}

.loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border-left-color: #00b4d8;
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 1001;
  }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: translate(-50%, -50%) rotate(0deg);
              transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      -webkit-transform: translate(-50%, -50%) rotate(360deg);
              transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  
  @keyframes spin {
    0% {
      -webkit-transform: translate(-50%, -50%) rotate(0deg);
              transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      -webkit-transform: translate(-50%, -50%) rotate(360deg);
              transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  
.image-to-ascii {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
}

@media (min-width: 768px) {
  .filter-controls {
      -webkit-column-count: 2;
              column-count: 2;
  }
}

/* @media screen and (min-width: 728px) {
  .image-to-ascii {
    flex-direction: row;
    gap: 2rem;
  }
  .filter-controls {
    flex-direction: row;
  }
} */



.dimension-input {
  width: 80px;
  margin-bottom: 1rem;
}

.file-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.upload-button {
  display: inline-block;
  background-color: #7f75ec;
  color: white;
  padding: 1rem 2rem;
  cursor: pointer;
  border-radius: .25rem;
  text-align: center;
  font-size: 16px;
  margin: 1rem;
  transition-duration: 0.4s;
}

.upload-button:hover {
  background-color: #635bb8;
}

.uploaded-image {
  max-width: 80%;
  /* max-height: 200px; */
  max-height: 40vw;
  margin-top: 1rem;
  object-fit: contain;
}

.ascii-output {
  background-color: black;
  color: white;
  padding: 1rem;
  border-radius: 5px;
  white-space: pre-wrap;
  text-align: center;
  overflow-x: auto;
  max-width: 100%;
  width: 100%;
  font-family: monospace;
}

.filter-controls {
  display: flex;
  flex-direction: column;
  -webkit-column-count: 1;
          column-count: 1;
  grid-gap: 10px;
  gap: 10px;
}


input[type='range'] {
  width: 100%;
}

.generate-art-button {
  background-color: #7f75ec;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 1rem;
  cursor: pointer;
  border-radius: 4px;
  transition-duration: 0.4s;
}

.generate-art-button:hover {
  background-color: #635bb8;
}

.copy-to-clipboard-button {
  align-items: end;
  margin: 1rem;
  margin-bottom: 3rem;
}

