body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  /* background-color: #ffe9d8; */
  background-color: whitesmoke;
  color:#2b2931
}

a {
  text-decoration: none;
  /* color: #ABDEE6 */
}

a:hover {
  text-decoration: underline;
}

.App {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.colorLayer {
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(270deg, #FF968A, #e4ac62, #ABDEE6, #abade6, #CBAACB, #FCB9AA);
  background-size: 1200% 1200%;
  animation: colorShift 60s ease infinite;
}

@keyframes colorShift {
  0% {
    background-position: 0% 50%;
  }

  17% {
    background-position: 100% 50%;
  }

  34% {
    background-position: 0% 50%;
  }

  51% {
    background-position: 100% 50%;
  }

  68% {
    background-position: 0% 50%;
  }

  85% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

body.dark-mode {
  background-color: #1f1f25;
  color: #f0f0f0;
}

body.dark-mode .header {
  color: #f0f0f0;
}

body.dark-mode .aboutMe {
  background-color: #2b2b2b;
  color: #f0f0f0;
}

body.dark-mode .toggle-dark-mode {
  background-color: #232328;
  color: #f0f0f0;
}

body.dark-mode .navigation .colorLayer {
  background: linear-gradient(270deg, #2b2b2b, #1a1a1a);
}

/* Dark mode button styles */
.toggle-dark-mode {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 8px 16px;
  background-color: #fff;
  border: none;
  font-size: 16px;
  color: #1b1b1b;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1000;
}

.toggle-dark-mode:focus {
  outline: none;
}

.navWrap {
  display: flex;
}

.navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

.navigation ul {
  display: flex;
  list-style-type: none;
}

.navigation .nav-left a {
  margin-right: auto;
  color: #ffffff;
  font-weight: 600;
}

.navigation .nav-right {
  margin-left: auto;
  margin-right: 1rem;
  justify-content: flex-end;
}


.navigation li {
  /* margin-left: 1rem; */
  margin-right: 1rem;
}

.navigation a {
  color: #fff;
  text-decoration: none;
}

.navigation a:hover {
  text-decoration: underline;
}

.home-header {
  text-align: center;
  color: #fff;
  padding-top: 4rem;
  /* padding-bottom: 2rem; */
  margin: 1rem;
}

.text-box {
  display: flex;
  flex-direction: column;
  background-color: #4e4e4e;
  padding: 2rem 3rem;
  margin: 2rem;
  color: #fff;
  text-align: left;
  border-radius: 10px;
  opacity: 0.8;
  max-width: 60%;
  vertical-align: middle;
}

.text-box h2 {
  margin-top: 0;
}

.blogWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-top: 3rem;
}

.projectsWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-top: 3rem;
}

.artWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-top: 3rem;
}

.home-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* min-height: 100vh;
  padding-top: 3rem; */
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-left-color: #e1903f;
  animation: spin 1s linear infinite;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.link{
  color:#ABDEE6;
}