.project-box-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 70%;
}

.project-box {
    display: flex;
    flex-direction: column;
    background-color: #7f75ec;
    padding: 2rem 3rem;
    border: 2px solid #abdee600;
    /* margin: 3rem; */
    color: #fff;
    text-align: left;
    border-radius: 10px;
    opacity: 0.8;
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
    /* max-width: 60%; */
  }

.project-box:hover {
    /* border: 2px solid #ABDEE6; */
    background-color: #635bb8;
    transition: 0.4s;
}

body.dark-mode .project-box {
    /* background-color: #7f75ec; */
    color: #fff;
    opacity: 0.8;
}

a:hover {
    text-decoration: none;
}

.ascii-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    padding-top: 3rem;
}

