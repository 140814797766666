.image-to-ascii {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
}

@media (min-width: 768px) {
  .filter-controls {
      column-count: 2;
  }
}

/* @media screen and (min-width: 728px) {
  .image-to-ascii {
    flex-direction: row;
    gap: 2rem;
  }
  .filter-controls {
    flex-direction: row;
  }
} */



.dimension-input {
  width: 80px;
  margin-bottom: 1rem;
}

.file-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.upload-button {
  display: inline-block;
  background-color: #7f75ec;
  color: white;
  padding: 1rem 2rem;
  cursor: pointer;
  border-radius: .25rem;
  text-align: center;
  font-size: 16px;
  margin: 1rem;
  transition-duration: 0.4s;
}

.upload-button:hover {
  background-color: #635bb8;
}

.uploaded-image {
  max-width: 80%;
  /* max-height: 200px; */
  max-height: 40vw;
  margin-top: 1rem;
  object-fit: contain;
}

.ascii-output {
  background-color: black;
  color: white;
  padding: 1rem;
  border-radius: 5px;
  white-space: pre-wrap;
  text-align: center;
  overflow-x: auto;
  max-width: 100%;
  width: 100%;
  font-family: monospace;
}

.filter-controls {
  display: flex;
  flex-direction: column;
  column-count: 1;
  gap: 10px;
}


input[type='range'] {
  width: 100%;
}

.generate-art-button {
  background-color: #7f75ec;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 1rem;
  cursor: pointer;
  border-radius: 4px;
  transition-duration: 0.4s;
}

.generate-art-button:hover {
  background-color: #635bb8;
}

.copy-to-clipboard-button {
  align-items: end;
  margin: 1rem;
  margin-bottom: 3rem;
}
